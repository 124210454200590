import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  TypedUseQueryStateResult,
} from "@reduxjs/toolkit/query/react";
import { Api } from "@/types/api";
import { createSelector, SerializedError } from "@reduxjs/toolkit";
import { object, safeParse, string } from "valibot";

export interface ApiItemCloudStatus {
  service: "cloud" | "google";
  fileCount: number;
  fileReady: boolean;
  itemId: string;
}
interface ItemStatusReq {
  orderId: string;
  brand: string;
  barcode: string;
}

interface ItemListReq {
  orderId: string;
  brand: string;
  barcodes: string[];
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${import.meta.env.VITE_APP_API_URI}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      if (token && token !== "") {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrderById: builder.query<Api.IProductionOrder, string>({
      query: (orderId: string) => ({ url: `production/order/${orderId}` }),
      providesTags: (_) => ["ProductionOrder"],
    }),
    getItemCloudStatus: builder.query<ApiItemCloudStatus, ItemStatusReq>({
      query: ({ orderId, brand, barcode }) => ({
        url: `production/order/${orderId}/item/${barcode}/cloud`,
        params: { brand },
      }),
      keepUnusedDataFor: 60 * 5,
    }),
    listItemCloudStatus: builder.query<ApiItemCloudStatus[], ItemListReq>({
      queryFn: async ({ orderId, brand, barcodes }, _api, _opts, baseQuery) => {
        try {
          const data = [];
          for (const barcode of barcodes) {
            if (!barcode) {
              continue;
            }
            const response = await baseQuery({
              url: `production/order/${orderId}/item/${barcode}/cloud`,
              params: { brand },
            });
            data.push(response.data);
          }

          return {
            data,
          };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
      keepUnusedDataFor: 60 * 5,
    }),
  }),
  tagTypes: ["ProductionOrder"],
});

export const {
  useGetOrderByIdQuery,
  useGetItemCloudStatusQuery,
  useListItemCloudStatusQuery,
} = api;

type GetOrderByIdArg = TypedUseQueryStateResult<
  Api.IProductionOrder,
  string,
  BaseQueryFn
>;

export const selectOrderHeader = createSelector(
  (res: GetOrderByIdArg) => res.data,
  (_res: GetOrderByIdArg, orderId: string) => orderId,
  (data, orderId) => {
    console.log(data);
    if (!data) {
      return;
    }

    return {
      orderId,
      brand: data.brand_name,
      name: data.shipping_address.lastname,
      isRush: data.is_rush,
    };
  },
);

export const selectDepartmentItems = createSelector(
  (res: GetOrderByIdArg) => res.data,
  (_res: GetOrderByIdArg, department: string) => department,
  (data, department) => {
    if (data) {
      return data.items.filter((item) => item.product_name === department);
    }
  },
);

const apiErrorDataSchema = object({
  errorType: string(),
  errorMessage: string(),
});

export const unwrapErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined,
) => {
  if (!error) {
    return "";
  }
  if ("status" in error) {
    const errorData = safeParse(apiErrorDataSchema, error.data);
    console.log(errorData);
    if (errorData.success) {
      return errorData.output.errorMessage;
    }
    return "An error occurred";
  }

  return error.message;
};
