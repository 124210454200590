import cn from "classnames";

import "@ui/components/scan-row.scss";
import BaseButton from "@/ui/base/button";

export interface ScanRowProps {
  checkActive?: boolean;
  children?: React.ReactNode;
  error?: string;
}

export function ScanRow({
  checkActive = false,
  children = null,
  error,
}: ScanRowProps) {
  const checkClass = cn("scan-row__checkmark", {
    valid: checkActive,
  });

  return (
    <section className="scan-row__wrapper">
      <section className="scan-row__input-row">
        {children}
        <div className={checkClass}>✓</div>
      </section>
      <section className="scan-row__error-row">{error}</section>
    </section>
  );
}

export interface ScanRowInputItemProps {
  text: string;
  status: "valid" | "error" | "active" | "inactive";
  onClick: () => void;
}

export function ScanRowInputItem({
  text = "",
  status = "inactive",
  onClick = () => {},
}: ScanRowInputItemProps) {
  const inputClass = cn("scan-row__scan-input", {
    valid: text.length > 0 && status === "valid",
    error: status === "error",
    inactive: status === "inactive",
    active: status === "active",
  });

  const clearBtn =
    text && status !== "valid" ? (
      <BaseButton className="delete-btn" onClick={onClick}>
        X
      </BaseButton>
    ) : null;

  return (
    <div className={inputClass}>
      <span>{text}</span>
      {clearBtn}
    </div>
  );
}
